/* eslint-disable sort-keys-fix/sort-keys-fix */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import './main.css';

import DatePicker from 'react-datepicker';

const DateField = ({
  initialDate, minDate, maxDate, disabled, onChange,
}) => {
  const [date, setDate] = useState(initialDate);
  const datePickerClassNames = classnames({
    'input -large -calendar mr-sm-1 -full-mobile picker__input': true,
    disabled,
  });

  useEffect(() => setDate(initialDate), [initialDate]);

  const dateChanged = (updatedDate) => {
    setDate(updatedDate);
    onChange(updatedDate);
  };

  return (
    <DatePicker
      className={datePickerClassNames}
      selected={date}
      onChange={dateChanged}
      minDate={minDate}
      disabled={disabled}
      maxDate={maxDate}
    />
  );
};

DateField.propTypes = {
  initialDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

DateField.defaultProps = {
  minDate: null,
  maxDate: null,
  disabled: false,
};

export default DateField;
