import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = forwardRef((props, ref) => {
  const timeZone = moment.tz(props.timezone).zoneAbbr();
  const newTime = `${props.value} ${timeZone}`;
  const newProps = { ...props, value: newTime };
  return <input {...newProps} ref={ref} />;
});

const TimeField = ({
  initialTime,
  timeInterval,
  minTime,
  maxTime,
  onChange,
  position,
  tripTimezone,
}) => {
  const [time, setTime] = useState(initialTime);
  const classes = 'input -large -clock mr-sm-1 -full-mobile'
  + ' picker__input ml-sm-1';
  useEffect(() => setTime(initialTime), [initialTime]);

  const timeChanged = (updatedTime) => {
    setTime(updatedTime);
    onChange(updatedTime);
  };

  return (
    <DatePicker
      selected={time}
      minTime={minTime}
      maxTime={maxTime}
      onChange={timeChanged}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeInterval}
      popperPlacement={position}
      dateFormat="h:mm aa"
      className={classes}
      customInput={<CustomInput timezone={tripTimezone} />}
    />
  );
};

TimeField.propTypes = {
  initialTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  timeInterval: PropTypes.string.isRequired,
  tripTimezone: PropTypes.string,
};

TimeField.defaultProps = {
  initialTime: new Date(),
  maxTime: moment().endOf('day').toDate(),
  minTime: new Date(),
  position: '',
};

export default TimeField;
